<template>
  <div class="d-flex flex-column overflow-auto vh-100">
    <upload-file id="upload-evidence" :req-uri="`/jobs/${data.id}/upload`" :req-params="uploadReqParams" />
    <sticky-header :title="$route.meta.title">
      <template #buttons >
        <b-button v-if="clockedIn" @click.prevent="noEntry" style="margin: 5px" variant="primary"><b-spinner class="mx-4" small v-if="loading " /><span v-if="!loading">No Entry</span></b-button>
        <b-button  @click.prevent="clockInOut" style="margin: 5px" variant="primary"><b-spinner class="mx-4" small v-if="loading" /><span v-if="!loading">{{ clockedIn ? 'End Job' : 'Start Job' }}</span></b-button>
      </template>
    </sticky-header>
    <div class="flex-fill p-3">
      <div class="d-flex flex-column mt-5 py-3 justify-content-center" v-if="loading">
        <b-spinner class="align-self-center" variant="primary" />
        <p class="align-self-center mt-1 mb-0">Loading...</p>
      </div>
      <div v-if="!loading">
        <b-row class="mb-4">
          <b-col cols="12" lg="4">
            <b-card class="h-100" title="Scheduled Time" v-if="data">
              <p class="mb-0"><strong>Starts At: </strong>{{ moment(data.started_at).format('HH:mm') }}</p>
              <p class="mb-0"><strong>Ends At: </strong>{{ moment(data.started_at).add(data?.duration_min , 'minutes').format('HH:mm') }}</p>
            </b-card>
          </b-col>
          <b-col cols="12" lg="4">
            <b-card class="h-100" title="Location Address" v-if="data">
              <p v-html="data.address && data.address.address_snippet.split(',').join(',<br/>').replace(data.address.postcode, `<br/>${data.address.postcode}`)"></p>
            </b-card>
          </b-col>
          <b-col cols="12" lg="4">
            <b-card class="h-100" title="Access" v-if="data.property?.key">
              <p class="mb-0"><strong>Key Hook: </strong>{{ data.property.key.key_hook }}</p>
              <p class="mb-0"><strong>Key Box: </strong>{{ data.property.key.keybox_code }}</p>
            </b-card>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col cols="12" lg="8">
            <b-card class="h-100" title="Job Activity" v-if="data.attendance && (data.attendance.length > 0)">
              <p class="mb-0" v-for="attendance in data.attendance" :key="attendance.id">
                {{ attendance.user.name }} |
                {{ moment(attendance.start).format('DD/MM/YYYY HH:mm') }} - {{ attendance.end ? moment(attendance.end).format('HH:mm') : 'Ongoing' }} |
                {{ (attendance.end ? moment(attendance.end) : moment()).diff(moment(attendance.start), 'hours') }} hours {{ (attendance.end ? moment(attendance.end) : moment()).diff(moment(attendance.start), 'minutes')%60 }} minutes
              </p>
            </b-card>
          </b-col>
          <b-col cols="12" lg="4">
            <b-card class="h-100" title="Reason for No Entry" v-if="data.no_entry_reason">
                {{ data.no_entry_reason }}
                <div class="text-muted" v-if="data?.no_entry_user?.name">
                  — {{ data.no_entry_user.name }}
                </div>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-card body-class="p-0">
              <b-list-group flush>
                <b-list-group-item :key="task.id" v-for="task in data.tasks"  :style="{'background-color': task.is_header ? 'rgba(0, 0, 0, 0.03)' : null}">
                  <div class="d-flex flex-row" v-if="task.is_header">
                      <p class="mb-0">{{ task.name }}</p>
                  </div>
                  <div class="d-flex flex-column" v-else>
                    <div class="d-flex flex-row">
                      <div class="flex-fill">
                        <p class="font-weight-bold mb-2">{{ task.name }}</p>
                        <p class="text-muted mb-0">{{ task.description }}</p>
                        <p class="font-weight-semibold mb-0" v-if="task.completedBy.length > 0">Marked Complete By: <a class="text-muted" v-for="(cleaner, key) in task.completedBy" :key="key">{{ cleaner.name }}{{ (key == (task.completedBy.length - 1)) ? '' : ',' }} </a></p>
                        <p class="font-weight-semibold mb-0" v-if="task.voidedBy.length > 0">Marked N/A By: <a class="text-muted" v-for="(cleaner, key) in task.voidedBy" :key="key">{{ cleaner.name }}{{ (key == (task.voidedBy.length - 1)) ? '' : ',' }} </a></p>
                        <a class="font-weight-bold mb-0" :href="task.upload.url" target="_blank" v-if="task.upload">View Upload</a>
                      </div>
                      <div class="align-self-center d-flex flex-md-row flex-column" v-if="clockedIn">
                        <b-button @click.prevent="upload(task.id)" class="mb-1 mb-md-0 ml-md-1" variant="primary" v-b-popover.top.hover="`Upload Evidence`"><fa-icon icon="upload" /></b-button>
                      </div>
                    </div>

                    <b-form-group>
                      <b-form-radio-group
                        v-model="task.checked"
                        v-if="clockedIn"
                        @change="updateTasks"
                      >
                        <b-form-radio class="align-self-center mr-2" value="N/A">N/A</b-form-radio>
                        <b-form-radio class="align-self-center mr-2" value="Complete">Complete</b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </b-card>
            <p class="mt-2 px-4 text-muted">Each cleaner assigned to this job have their own separate checklist. You won't see other cleaners' completions.</p>
            <b-card v-if="clockedIn">
              <b-form-input class="mb-2" placeholder="Current Keybox Code" type="text" v-model="keyboxCode" />

              <b-button @click.prevent="onClickCompleteJob" variant="primary">
                <b-spinner class="mx-4" small v-if="loading" />
                <span v-if="!loading">Complete Job</span>
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import StickyHeader from '../components/StickyHeader.vue'
import UploadFile from '../components/UploadFile.vue'
import { mapActions, mapState } from 'vuex'

export default {
  components: { StickyHeader, UploadFile },
  data() {
    return {
      validationErrors: {},
      genericError: undefined,
      uploadReqParams: {
        task_id: 0,
      },
      data: {},
      keyboxCode: '',
    };
  },
  async mounted() {
    const job = await this.fetchSingle(this.$route.params.jobId);

    this.data = job;
    this.setClockedIn(job.isClockedIn);
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('jobs', ['loading', 'saving', 'clockedIn']),
  },
  methods: {
    ...mapActions('jobs', ['fetchSingle', 'updateOrCreate', 'clockIn', 'clockOut', 'setClockedIn']),
    ...mapActions('propertyKeys', ['updateKeyboxCode']),
    moment,
    clockInOut() {
      if (this.clockedIn) {
        let allChecked = true;

        for(const taskId in this.data.tasks) {
          const task = this.data.tasks[taskId];
          if (task.checked != null) continue;

          allChecked = false;
          break;
        }

        if (allChecked) {
          this.clockOut(this.$route.params.jobId).then((data) => {
            this.$bvModal.msgBoxOk(data.message, {
              title: 'End Job',
              okVariant: data.clockedIn ? 'danger' : 'primary',
              centered: true
            });
          }).catch(() => {
            this.$bvModal.msgBoxOk('There was an error clocking you out of this job.', {
              title: 'End Job',
              okVariant: 'danger',
              centered: true
            });
          });

          this.fetchSingle(this.$route.params.jobId).then(response =>  this.data = response);
        } else {
          this.$bvModal.msgBoxConfirm("The checklist for this job is incomplete, are you sure you wish to finish?", {
            title: 'Incomplete Job',
            okText: 'Yes, Finish',
            okVariant: 'danger',
            cancelText: 'No, I\'ll check again',
            cancelVariant: 'light',
            centered: true
          }).then(actioned => {
            if (!actioned) {
              return;
            }
            this.clockOut(this.$route.params.jobId).then((data) => {
              this.$bvModal.msgBoxOk(data.message, {
                title: 'End Job',
                okVariant: data.clockedIn ? 'danger' : 'primary',
                centered: true
              });
            }).catch(() => {
              this.$bvModal.msgBoxOk('There was an error clocking you out of this job.', {
                title: 'End Job',
                okVariant: 'danger',
                centered: true
              });
            });
          });
        }
      } else {
        this.clockIn(this.$route.params.jobId).then((data) => {
          this.$bvModal.msgBoxOk(data.message, {
            title: 'Start Job',
            okVariant: !data.clockedIn ? 'danger' : 'primary',
            centered: true,
            okTitle: data.job ? 'View Job' : 'Ok'
          }).then(() => {
            if (data.job) {
              this.$router.push({name: 'jobs.details', params: {'jobId': data.job.id}});
            }
          });

          this.fetchSingle(this.$route.params.jobId).then(response =>  this.data = response);
        }).catch(() => {
          this.$bvModal.msgBoxOk('There was an error clocking you in to this job.', {
            title: 'Start Job',
            okVariant: 'danger',
            centered: true
          });
        })
      }
    },
    noEntry() {
      let noEntryReason = '';
      let noEntryReasonAdditional = '';
      let job = this.data;

      this.$bvModal.msgBoxConfirm(
        <div>
          Reason for no entry?
          <p>
            <b-form-select vModel={noEntryReason}>
              <option>No Key</option>
              <option>Wrong Key</option>
              <option>Guest Refusal</option>
              <option>Child in the house</option>
              <option>Dog in the house</option>
              <option>Other</option>
            </b-form-select>
          </p>
          <p>
            Additional Information
            <b-form-input vModel={noEntryReasonAdditional}></b-form-input>
          </p>
        </div>, {
          title: 'No Entry',
          okText: 'Yes',
          okVariant: 'danger',
          cancelText: 'No',
          cancelVariant: 'light',
          centered: true
        }).then(shouldContinue => {
        if (!shouldContinue) {
          return;
        }

        if (noEntryReasonAdditional.length !== 0) {
          noEntryReason = noEntryReason.concat(' — ').concat(noEntryReasonAdditional);
        }
        job.no_entry_reason = noEntryReason;

        return this.updateOrCreate(job).then(
          () => this.clockOut(this.$route.params.jobId),
          () => this.fetchSingle(this.$route.params.jobId).then(response =>  this.data = response)
        );
      });

    },
    onClickCompleteJob() {
      if (!this.clockedIn) {
        alert('Please Start Job first.')
        return;
      }

      this.updateKeyboxCode({
        property_id: this.data.property.id,
        keybox_code: this.keyboxCode
      });

      this.clockInOut();

      this.$router.push('/jobs');
    },
    updateTasks() {
      if (!this.clockedIn) {
        alert('Please Start Job before attempting to complete the checklist.')
        return;
      }

      this.updateOrCreate({
        ...this.data, // None of this matters, the API should reject the changes.
        type: this.data.interval_days === 0 ? 'single': 'recurring',
        tasks: this.data.tasks
      }).then(() => {
        this.genericError = undefined;
        this.closeModal();

        this.fetchSingle(this.$route.params.jobId).then(response =>  this.data = response);

        return this.fetchSingle(this.$route.params.jobId);
      }).catch(err => {
        if (err.response && err.response.status === 422) {
          err.response.json().then(json => {
            this.validationErrors = json.errors;
          });
        } else {
          this.genericError = 'There was an error saving just now. Please try again later.';
        }
      });
    },
    upload(id) {
      this.uploadReqParams = { task_id: id };
      this.$bvModal.show('upload-evidence');
    }
  },
  watch: {
    async '$route'() {
      const job = await this.fetchSingle(this.$route.params.jobId);

      this.data = job;
      this.setClockedIn(job.isClockedIn);
    }
  }
}
</script>

<style>

</style>
