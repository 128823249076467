<template>
  <div>
    <b-modal no-close-on-backdrop :id="id" size="md" title="Upload File">
      <b-row>
        <b-col cols="12">
          <b-form-file
            v-model="file"
            :disabled="uploading"
            accept=".jpg, .png, .gif, .heic, .mp4"
            drop-placeholder="Drop file here..."
            placeholder="Choose a file or drop it here..."
          ></b-form-file>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button @click.prevent="closeModal" :disabled="uploading" variant="light">Close</b-button>
        <b-button @click.prevent="uploadFile" :disabled="!file || uploading" variant="primary">
          <b-spinner class="mx-4" variant="light" small v-if="uploading"/>
          <span v-if="!uploading">Upload</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      file: null,
      uploading: false,
      errorMessage: '',
    };
  },
  props: {
    reqParams: Object,
    reqUri: String,
    id: String,
  },
  methods: {
    closeModal() {
      this.file = null;
      this.$bvModal.hide(this.id);
    },
    uploadFile() {
      this.uploading = true;
      const formData = new FormData();
      formData.append('file', this.file, this.file.name);
      Object.keys(this.reqParams).map((key) => {
        formData.append(key, this.reqParams[key]);
      });

      window.apiFetch(this.reqUri, {
        method: 'POST',
        body: formData,
      }).then(() => {
        this.uploading = false;
        this.closeModal();
      }).catch((err) => {
        this.uploading = false;
        this.errorMessage = `Could not upload evidence: ${err.statusText}`;
      });
    }
  }
}
</script>

<style>

</style>
